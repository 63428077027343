import _ from "lodash";

//! @ngInject
export function visitModalCtrl(
  $scope,
  $rootScope,
  NgTableParams,
  $uibModalInstance,
  DatabaseApi,
  toaster,
  SweetAlert,
  Analytics,
  $timeout,
  $uibModal,
  VisitStatus,
  Consts,
  $templateCache,
  $compile,
  mfModal,
  wildcard,
  generalUtils
) {
    $scope.visit = $scope.$resolve.visit;
    $scope.overtimeMap = {};

    $rootScope.visitModalID = $scope.visit.id;
    $rootScope.activeModalId = $scope.visit.id;
    $scope.circleRadius = $scope.visit.assignableCaregiverRadius;
    var rMin = $scope.circleRadius * 0.8;
    var rMax = $scope.circleRadius;
    let direction = 1;
    let zoom;
    var caregiverMarkers = [];
    var map = null, mapCircle = null;
    var mapPopUp = null;
    var pop = null;
    let timeStamp;
    const excludedBoostAgenciesIds = [52]; // hide boost button for specific agencies
    const start = new Date($scope.visit.date);
    const onejan = new Date(start.getFullYear(), 0, 1);

    $scope.showMap = false;
    $scope.showCancelDiv = false;
    $scope.activePop = null;
    $scope.ids = {
      accepted: [],
    };
    $scope.totalAllowedTimePerWeek = 40;
    $scope.pageMin = 0;
    $scope.pageMax = 35;
    $scope.selectedPage = 1;
    $scope.isAllowedToBoostVisit = excludedBoostAgenciesIds.indexOf($rootScope.agencyId) === -1;

    const circleOptions = {
      radius: $scope.circleRadius,
      weight: 0.5,
      fillOpacity: 0.1,
    };
    const patientIcon = L.icon({
      iconUrl: "admin/images/patient2.png",
      iconSize: [32, 38],
      iconAnchor: [16, 16],
    });
    const caregiverIcon = L.icon({
      iconUrl: "admin/images/caregiver3.png",
      iconSize: [32, 29],
      iconAnchor: [16, 16],
    });
    const patientMarkerOptions = {
      icon: patientIcon,
      interactive: false,
    };

    $scope.days = [
      { fullName: "MONDAY", shortCaps: "MON", shortLow: "Sun" },
      { fullName: "TUESDAY", shortCaps: "TUE", shortLow: "Mon" },
      { fullName: "WEDNESDAY", shortCaps: "WED", shortLow: "Tue" },
      { fullName: "THURSDAY", shortCaps: "THU", shortLow: "Wed" },
      { fullName: "FRIDAY", shortCaps: "FRI", shortLow: "Thu" },
      { fullName: "SATURDAY", shortCaps: "SAT", shortLow: "Fri" },
      { fullName: "SUNDAY", shortCaps: "SUN", shortLow: "Sat" },
    ];

    const initialize = () => {
      window.scrollTo(0, 0);
      $scope.caregivers = DatabaseApi.caregivers() || {};
      resetTransformedCaregivers();
      
      if ($scope.visit.broadcastedTo) {
        initRadius();
      }
      $scope.map = { zoom: zoom, sentTo: [] };
      if ($scope.visit.patientAddressLoc) {
        $scope.map.center = {
          latitude: $scope.visit.patientAddressLoc.lat,
          longitude: $scope.visit.patientAddressLoc.lng,
        };
        $scope.map.home = {
          latitude: $scope.visit.patientAddressLoc.lat,
          longitude: $scope.visit.patientAddressLoc.lng,
        };
        setAnimation();
      }
      $scope.visit.acceptedBy.forEach(c => $scope.ids.accepted.push(c.id));
      $scope.weekNumber = Math.ceil(
        ((start - onejan) / 86400000 + onejan.getDay() + 1) / 7
      );
      $scope.popupTemplateParameter = {
        openChatPop: () => {
          if ($scope.activePop && $scope.visit) {
            $rootScope.openCaregiverModal($scope.activePop.id, $scope.activePop);
          }
        },
        getPhones: (id) => {
          const caregiversMap = DatabaseApi.caregivers() || {};
          return caregiversMap[id] ? caregiversMap[id].phoneNumbers : [];
        },
      };
      setTimeout(() => {
        mapChange();
      }, 300);
      changeZoom($scope.visit.assignableCaregiverRadius);
      getViewedData();
      getBroadcastedTo();
      setCaregiversStatus();
      getVisitInstances();
      getCaregiverOvertime();

      if (!$scope.visit.status) {
        $scope.visit.status = VisitStatus.getVisitStatus($scope.visit);
      }

      Analytics.event("visit-modal", {
        visitId: $scope.visit.id,
        status: $scope.visit.status,
        assigned: !!$scope.visit.assignedCaregiver,
        accepted: $scope.visit.acceptedBy && $scope.visit.acceptedBy.length ? $scope.visit.acceptedBy.length : 0,
      });
    };

    const initRadius = () => {
      $scope.circleRadius = $scope.visit.assignableCaregiverRadius;
      rMax = $scope.visit.assignableCaregiverRadius * 1.1;
      rMin = $scope.visit.assignableCaregiverRadius * 0.9;
    };

    const changeZoom = (distance) => {
      var d = Math.ceil(distance / 1600);
      if (d <= 1) zoom = 20;
      //else if(d <= 2) zoom = 15;
      else if (d <= 3) zoom = 19;
      else if (d <= 4) zoom = 18;
      else if (d <= 5) zoom = 17;
      else if (d <= 8) zoom = 16;
      else if (d <= 11) zoom = 15;
      else zoom = 14;
      //Math.max(17 - d, 9);
      if ($scope.map) $scope.map.zoom = zoom;
    };

    const setAnimation = () => {
      setInterval(() => {
        const radius = $scope.circleRadius;
        if (radius > rMax || radius < rMin) {
          direction *= -1;
        }
        $scope.circleRadius = radius + direction * 10;
      }, 20);
    };

    let id2idx = {};
    const mapChangeMarkers = () => {
      caregiverMarkers.forEach((value) => value.marker.remove());
      id2idx = {};

      if (map && $scope.map.sentTo) {
        caregiverMarkers = [];
        $scope.map.sentTo.forEach((value, i) => {
          const caregiverMarkerOptions = {
            icon: caregiverIcon,
            interactive: true,
            idx: i,
          };

          const coordinates = [value.location.latitude, value.location.longitude];
          const marker = L.marker(coordinates, caregiverMarkerOptions);
          marker.on("mouseover", showHideMapCaregiver);
          const caregiver = value.data;
          caregiverMarkers.push({ marker, data: caregiver });
          id2idx[caregiver.id] = i;
        });
        
        const markerClusters = L.markerClusterGroup({
          disableClusteringAtZoom: 14
        });
        const markerArray = caregiverMarkers.map(({ marker }) => marker);
        markerClusters.addLayers(markerArray);
        map.addLayer(markerClusters);
      }
    };

    const mapChange = () => {
      try {
        if (map) {
          map.setView(
            [$scope.map.center.latitude, $scope.map.center.longitude],
            $scope.map.zoom
          );
        } else {
          map = L.map("visit-modal-map", {
            center: [$scope.map.center.latitude, $scope.map.center.longitude],
            zoom: $scope.map.zoom,
          });
          L.tileLayer("https://d5nz7zext4ylq.cloudfront.net/{z}/{x}/{y}.png", {
            tileSize: 512,
            zoomOffset: -1,
            minZoom: 5,
            attribution: "",
            crossOrigin: true,
          }).addTo(map);

          mapCircle = L.circle(
            [$scope.map.home.latitude, $scope.map.home.longitude],
            circleOptions
          ).addTo(map);
          L.marker(
            [$scope.map.home.latitude, $scope.map.home.longitude],
            patientMarkerOptions
          ).addTo(map);
          mapChangeMarkers();
        }
      } catch (e) {
        console.log("no map yet", e);
        $timeout(mapChange, 100);
      }
    };
  
    $scope.showPopupById = (id) => $scope.showPopup(id2idx[id]);

    let flyTimeout = null;
    $timeout(() => {
      $scope.startFlyTimeout = (id)=> {
        $scope.stopFlyTimeout();
        flyTimeout = $timeout(()=> $scope.showPopupById(id), 200);
      };

      $scope.stopFlyTimeout = () => {
        if (flyTimeout) {
          $timeout.cancel(flyTimeout);
          flyTimeout = null;
        }
      };
    }, 400);

    $scope.locatePatientHome = () => {
      map.flyTo([$scope.map.home.latitude, $scope.map.home.longitude]);
    };

    $scope.showPopup = (idx) => {
      map.closePopup();
      mapPopUp = null;

      if (!$scope.map.sentTo[idx]) {
        console.error(`no sentTo[${idx}] error`);
        return;
      }

      const { data, location } = $scope.map.sentTo[idx];
      $scope.activePop = data;
      const el = $compile($templateCache.get("pop-map-visit.html"))($scope);

      $timeout(() => {
        if (pop) {
          pop.removeEventListener("click", (ev) => {});
        }

        const coordinates = [location.latitude, location.longitude];
        mapPopUp = L.popup()
          .setLatLng(coordinates)
          .setContent(el[0].outerHTML);

        map.flyTo(coordinates);
        $timeout(() => map.openPopup(mapPopUp), 50);
      });
    };

    const showHideMapCaregiver = (event) => {
      if (!$scope.map.sentTo || !event) {
      } else {
        if (event.target) {
          event.key = event.target.options.idx;
          $scope.showPopup(event.key);
        }
      }
    };

    const getBroadcastedTo = () => {
      if (timeStamp) {
        if (new Date().getTime() - 1000 < timeStamp) return;
      } else {
        timeStamp = new Date().getTime();
      }

      Analytics.event("visit-modal-broadcasted-to", {
        visitId: $scope.visit.id,
        status: $scope.visit.status,
        assigned: !!$scope.visit.assignedCaregiver,
        accepted: $scope.visit.acceptedBy.length,
      });

      DatabaseApi.getMatchingCaregivers($scope.visit.id).then((caregivers) => {
        $scope.visit.matchingCaregivers = caregivers.map(getTransformedCaregiver).filter(Boolean);
        $scope.visit.broadcastedTo = $scope.visit.matchingCaregivers.filter(caregiver => caregiver.appInstalled);
        $scope.filtered.broadcastedTo = $scope.visit.broadcastedTo.filter(filterCaregiver);

        $scope.gettingBroadcasted = false;

        $scope.visit.broadcastedTo.forEach(c => {
          $scope.map.sentTo.push({
            location: {
              latitude: c.addressGeoLocation.lat,
              longitude: c.addressGeoLocation.lng,
            },
            data: c,
          });
        });

        initRadius();
      });
    };

    const getCaregiverOvertime = () => {
      DatabaseApi.getVisitBroadcastCaregiverOvertime($scope.visit.id).then((req) => {
        if (req && req.data && req.data.caregivers) {
          for (const caregiver of req.data.caregivers) {
            $scope.overtimeMap[caregiver.caregiverId] = caregiver.overtime;
          }
        }
      });
    };
    
    $scope.unwrapContaineredCaregivers = (caregiversContainers) => {
      return _.map(caregiversContainers, (c) => c.caregiver);
    } 

    const getVisitInstances = () => {
      const url = wildcard(
        "agencies/:agencyId/visits/:visitId/visit_instances",
        $rootScope.agencyId,
        $scope.visit.id
      );
      DatabaseApi.get(url).then((res) => {
        $scope.visitInstances = res.data.visitInstances;
      },
      () => {
        toaster.pop("error", "Something went wrong");
      });
    };

    const mapVisits = (visitsArr) => {
      var found = false;
      visitsArr.forEach(v => {
        if (found) return;
        if (v.id === $scope.visit.id) {
          $scope.visit = angular.extend($scope.visit, v);
          changeZoom($scope.visit.assignableCaregiverRadius);
          if (v.id === $rootScope.activeModalId && !$scope.visit.cancelled) {
            getBroadcastedTo();
          }
          if (!$scope.visit.status) {
            $scope.visit.status = VisitStatus.getVisitStatus($scope.visit);
          }

          found = true;
        }
      });
    };
  
    $scope.filtered = {};
    const rebuildFilteredLists = () => {
      $scope.filtered.acceptedBy = _.filter($scope.visit.acceptedBy, filterCaregiverInContainer)
      $scope.filtered.calledAgency = _.filter($scope.visit.calledAgency, filterCaregiver);
      $scope.filtered.moreDetailsViewedBy = _.filter($scope.visit.moreDetailsViewedBy, filterCaregiverInContainer);
      $scope.filtered.previewedBy = _.filter($scope.visit.previewedBy, filterCaregiverInContainer);
      $scope.filtered.broadcastedTo = _.filter($scope.visit.broadcastedTo, filterCaregiver);
    }

    $scope.rebuildFilteredLists = rebuildFilteredLists;

    const setCaregiversStatus = () => {
      if ($scope.visit.assignedCaregiver) {
        $scope.visit.assignedCaregiver = getTransformedCaregiver($scope.visit.assignedCaregiver);
      }

      $scope.visit.acceptedBy = _.map($scope.visit.acceptedBy, transformCaregiverInContainer);
      $scope.visit.calledAgency = _.map($scope.visit.calledAgency, getTransformedCaregiver);
      $scope.visit.moreDetailsViewedBy = _.map($scope.visit.moreDetailsViewedBy, transformCaregiverInContainer);
      $scope.visit.previewedBy = _.map($scope.visit.previewedBy, transformCaregiverInContainer);
      $scope.visit.broadcastedTo = _.map($scope.visit.broadcastedTo, getTransformedCaregiver).filter(Boolean);

      rebuildFilteredLists();
    };

    $scope.isFilteredListEmpty = (listName) => {
      return _.isEmpty($scope.filtered[listName]);
    };

    $scope.areAllFilteredListsEmpty = ()=> {
      const {
        acceptedBy,
        moreDetailsViewedBy,
        previewedBy,
        calledAgency,
        broadcastedTo,
      } = $scope.filtered;
      
      const nonEmptyLists = [
        acceptedBy,
        moreDetailsViewedBy,
        previewedBy,
        calledAgency,
        broadcastedTo
      ]
        .filter(list => list && list.length > 0);
      
      return nonEmptyLists.length === 0;
    }

    const getViewedData = () => {
      const url = wildcard(
        "agencies/:agencyId/agency_member/:agencyMemberId/visits/:visitId/engagements2",
        $rootScope.agencyId,
        $rootScope.agencyMemberId,
        $scope.visit.id
      );
      DatabaseApi.get(url).then((res) => {
        $scope.visit.acceptedBy = res.data.acceptedBy.map((engagement) => { 
          return {
            caregiver: $scope.caregivers[engagement.caregiverId],
            requestedInstances: engagement.requestedInstances
          }
        });
        $scope.visit.previewedBy = res.data.previewedBy.map((caregiverId) => $scope.caregivers[caregiverId]);
        $scope.visit.moreDetailsViewedBy = res.data.moreDetailsViewedBy.map((engagement) => {
          return {
            caregiver: $scope.caregivers[engagement.caregiverId],
            reason: engagement.rejectedReason
          }
        });
        $scope.visit.calledAgency = res.data.calledAgency;
        setCaregiversStatus();
      });
    };

    const changePopup = (visit, caregiver, broadcast) => {
      if (broadcast) {
        caregiver = visit.assignedCaregiver;
        SweetAlert.swal(
          {
            title: "Remove Caregiver?",
            text: "Are you sure you want to remove the caregiver assigned to this case? we will notify the caregiver assigned that the visit was un assigned from the case and we will re broadcast the visit to all the available caregivers",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, remove caregiver",
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              Analytics.event("visit-modal-remove-caregiver", {
                visitId: visit.id,
                status: visit.status,
                removedCaregiver: visit.assignedCaregiver.id,
                accepted: visit.acceptedBy.length,
              });

              visit.assignedCaregiver = null;
              done(visit, null);
            }
          }
        );
      } else {
        SweetAlert.swal(
          {
            title: "Change Caregiver?",
            text: "Are you sure you want to change the caregiver assigned to this case? we will notify the caregiver assigned that the visit was un assigned from the case and we will assign the visit to the new caregiver",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, change caregiver",
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              Analytics.event("visit-modal-change-caregiver", {
                visitId: visit.id,
                status: visit.status,
                removedCaregiver: visit.assignedCaregiver
                  ? visit.assignedCaregiver.id
                  : undefined,
                newCaregiver: visit.caregiver ? visit.caregiver.id : undefined,
                accepted: visit.acceptedBy.length,
              });

              visit.assignedCaregiver = caregiver;
              done(visit, visit.assignedCaregiver);
            }
          }
        );
      }
    }

    const done = (visit, toCaregiver) => {
      const url = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/visits/:visitId/assigned_to",
        $rootScope.agencyId,
        $rootScope.agencyMemberId,
        visit.id
      );

      DatabaseApi.delete(url).then((res) => {
        if (toCaregiver) {
          DatabaseApi.put(url, { id: toCaregiver.id }).then((res) => {
            if (res.data.assignedWithOvertime) {
              toaster.pop({
                  type: 'warning',
                  title: 'Warning',
                  body: `Caregiver was assigned with increased caregiver overtime`
              });
            } else {
              toaster.pop("success", "Success", "Caregiver has assigned to visit");
            }
            $uibModalInstance.dismiss("cancel");
          }, (err) => {
            let errText = "";
            if (err.data) errText = err.data.error ? err.data.error : "";

            if (err.status === 409)
              toaster.pop("error", "Assigning Conflict", errText);
            else
              toaster.pop("error", "Something went wrong", errText || err.data);
            }
          );
        } else {
          $uibModalInstance.dismiss("cancel");
        }
      }, (err) => {
        toaster.pop("error", "Something went wrong", err.data);
      });
    }

    $scope.popClicked = () => {
      $scope.popupTemplateParameter.openChatPop();
    };

    $scope.getBroadcastedTo = () => {
      if ($scope.gettingBroadcasted) return;
      $scope.gettingBroadcasted = true;

      getBroadcastedTo();
    };

    $scope.pagination = (num) => {
      $scope.selectedPage = num;
      $scope.pageMin = (num - 1) * 35;
      $scope.pageMax = num * 35;
    };

    $scope.assignModal = (visit, caregiver) => {
      $uibModal.open({
        templateUrl: "admin/views/assign-modal.html",
        size: "md",
        controller: "assignModalCtrl",
        windowClass: "top-top",
        resolve: {
          visit: () => visit,
          caregiver: () => caregiver,
        },
      });
    };

    $scope.reSendNotification = (id) => {
      $scope.disable = true;
      const url = wildcard(
        "agencies/:agencyId/visits/:visitId",
        $rootScope.agencyId,
        id
      );
      DatabaseApi.post(url).then(() => {
        Analytics.event("visit-modal-rebroadcast", {
          visitId: $scope.visit.id,
          status: $scope.visit.status,
          assigned: !!$scope.visit.assignedCaregiver,
          accepted: $scope.visit.acceptedBy.length,
        });

        toaster.pop("success", "Success", "Visit has been broadcasted");
        $uibModalInstance.dismiss("cancel");
      },
      () => {
        toaster.pop("error", "Something went wrong", "please try again");
        $scope.disable = false;
      });
    };

    $scope.closeModal = () => {
      $uibModalInstance.dismiss("cancel");
    };

    $scope.removeRequest = (caregiverId) => {
      if (!$scope.visit || !caregiverId) return;

      const url = wildcard(
        "agency/:agencyId/visits/:visitId/caregivers/:caregiverId/ignore_request",
        $rootScope.agencyId,
        $scope.visit.id,
        caregiverId
      );
      DatabaseApi.put(url).then(
        (res) => {
          toaster.pop("success", "Successfully rejected caregiver");
          Analytics.event("remove-caregiver-visit-request", {
            visitId: $scope.visit.id,
            caregiver: caregiverId,
            accepted: $scope.visit.acceptedBy.length,
          });
          $scope.visit.acceptedBy = $scope.visit.acceptedBy.filter(
            (cg) => cg.id !== caregiverId
          );
        },
        (err) => {
          toaster.pop("error", "Oops...", "Failed to rejected caregiver");
        }
      );
    };

    $scope.pickCaregiver = (visit, caregiver, broadcast) => {
      if (!caregiver)
        return toaster.pop(
          "error",
          "Something went wrong",
          "please reload/refresh page"
        );
      if (visit.status === "scheduled") {
        if (new Date(visit.startTime).getTime() < new Date().getTime()) {
          SweetAlert.swal({
            title: "The case has started already!",
            text: "Cannot remove the caregiver assigned to a case after it has started! \n In order to remove the caregiver, cancel the visit and create a new one to broadcast to available caregivers.",
            type: "error",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "OK",
            closeOnConfirm: true,
          });
        } else {
          changePopup(visit, caregiver, broadcast);
        }
      } else if (!visit.dayTimeParams) {
        const caregiverId = caregiver.caregiver
          ? caregiver.caregiver
          : caregiver.id;

        const url = wildcard(
          "agencies/:agencyId/agency_members/:agencyMemberId/visits/:visitId/assigned_to",
          $rootScope.agencyId,
          $rootScope.agencyMemberId,
          visit.id
        );
        const body = {
          caregiverId: caregiverId,
          partialAssignDetails: null,
          flexibleVisitRequestParams: null,
        };
        if (caregiver.acceptedData) {
          body.flexibleVisitRequestParams = caregiver.acceptedData;
        }
        DatabaseApi.put(url, body).then((res) => {
            if (res.data.assignedWithOvertime) {
              toaster.pop({
                  type: 'warning',
                  title: 'Warning',
                  body: `Caregiver was assigned with increased caregiver overtime`
              });
            } else {
              toaster.pop("success", "Success", "Caregiver has assigned to visit");
            }

            Analytics.event("visit-modal-assigned", {
              visitId: visit.id,
              status: "scheduled",
              newCaregiver: caregiverId,
              accepted: visit.acceptedBy.length,
            });

            //$rootScope.sweepScore.points += 100;
            $uibModalInstance.dismiss("cancel");
          }, (err) => {
            var errText = "";
            if (err.data) errText = err.data.error ? err.data.error : "";

            if (err.status === 409)
              toaster.pop("error", "Assigning Conflict", errText);
            else
              toaster.pop("error", "Something went wrong", errText || err.data);
          }
        );
      } else {
        $scope.assignModal(visit, caregiver);
      }
    };

    $scope.getToolTipAcceptedText = (dayTimeParams) => {
      if (!dayTimeParams) return "";

      return dayTimeParams.dayTimes.map(day => {
        `${$scope.days[day.day].shortLow} - Start: ${day.startTime}, End: ${day.endTime}`
      }).join(` | `);
    };

    $scope.boostModal = (visit) => {
      const PRICE_PER_SMS = Math.ceil(
        Consts.SEGMENT_AMOUNT * Consts.PRICE_PER_SEGMENT
      );

      Analytics.event("Boost clicked", {
        type: "VISIT_BOOST",
        visit: visit.id,
      });

      const notInstalledCaregivers = (visit.matchingCaregivers || [])
        .filter((curCaregiver) => !curCaregiver.appInstalled);

      const modalInstance = $uibModal.open({
        templateUrl: "admin/views/boost-modal.html",
        size: "lg",
        controller: "boostModalCtrl",
        resolve: {
          textAmountToBroadcast: () => notInstalledCaregivers.length,
          priceOverallCents: () => notInstalledCaregivers.length * PRICE_PER_SMS,
          type: () => "VISIT_BOOST",
          visitId: () => visit.id,
        },
        windowClass: "top-top",
      });

      modalInstance.result.then((res) => {
        if (res === "BOOST") {
          const url = wildcard(
            "agency/:agencyId/:agencyMemberId/boost",
            $rootScope.agencyId,
            $rootScope.agencyMemberId
          );
          const body = {
            caregiverIds: notInstalledCaregivers.map(caregiver => caregiver.id),
            visit: visit.id,
            price: PRICE_PER_SMS,
            type: "VISIT_BOOST",
          };
          DatabaseApi.post(url, body).then((res) => {
            Analytics.event("Boost done", {
              type: "VISIT_BOOST",
              boostSentAmount: notInstalledCaregivers.length,
              visit: visit.id,
              pricePerSms: PRICE_PER_SMS,
            });
            toaster.pop("success", "Boosted successfully", "Allow 10 minutes for sms to be sent");
          }, (err) => {
            const errorMessage = err.data.error ? err.data.error : "Please try again.";
            toaster.pop("error", "Boost failed", errorMessage);
          });
        }
      });
    };

    $scope.openStopBroadcastModal = () => {
      const modal = mfModal.create({
        subject: "Stop broadcast",
        message: `
          Are you sure you want to stop the broadcast?
          This will stop the broadcast for the whole series.
        `,
        cancelLabel: "Cancel",
        confirmLabel: "Stop broadcast selected events",
        variant: "danger",
        extraAction: true,
        extraActionLabel: "Stop broadcasting whole series",
        onExtraAction () {
          modal.setLoading(true);

          const url = wildcard(
            "agencies/:agencyId/agency_member/:agencyMemberId/broadcast_visits/:visitBroadcastId",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            $scope.visit.id
          );
          DatabaseApi.delete(url).then((res) => {
            toaster.pop("success", "Successfully stopped visit broadcast");
            $rootScope.$emit("refresh_visits");
            modal.close();
          }, (err) => {
            toaster.pop("error", "Oops...", "Can't stop broadcast");
            modal.update({
              isLoading: false,
              message: "Something went wrong, please try again.",
            });
          });
        },
        onConfirm: () => {
          modal.setLoading(true);

          const url = wildcard(
            "agencies/:agencyId/agency_member/:agencyMemberId/broadcast_visits/:visitBroadcastId",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            $scope.visit.id
          );
          DatabaseApi.delete(url).then((res) => {
            toaster.pop("success", "Successfully stopped visit broadcast");
            $rootScope.$emit("refresh_visits");
            modal.close();
          }, (err) => {
            toaster.pop("error", "Oops...", "Can't stop broadcast");
            modal.update({
              isLoading: false,
              message: "Something went wrong, please try again.",
            });
          });
        },
      });
    };

    $scope.$watch("circleRadius", () => {
      if (mapCircle && $scope.circleRadius) {
        mapCircle.setRadius($scope.circleRadius);
      }
    }, true);

    $scope.$watch("map.sentTo", mapChangeMarkers, true);

    $scope.$on("modal.closing", () => {
      $rootScope.visitModalID = undefined;
    });

    $rootScope.$on("visit_id_changed", (event, data) => {
      handleVisitChange(data);
    });
    $rootScope.$on("visit_changed", (event, data) => {
      handleVisitChange(data);
    });

    function handleVisitChange(data){
      if (!data || data.visitId !== $scope.visit.id) return;

      getViewedData();

      let visitsArr = DatabaseApi.visits().broadcasts;
      if (visitsArr !== undefined) {
        mapVisits(visitsArr);
      } else {
        DatabaseApi.getVisits().then((db) => {
          visitsArr = db.visits.broadcasts;
          mapVisits(visitsArr);
        });
      }
    }
    
    $rootScope.$on("caregiver_changed", (event, data) =>{
      if (data.id !== $scope.visit.id) return;
      resetTransformedCaregivers();
      setCaregiversStatus();
    });

    $scope.sanitizePhoneType = (phoneType) => phoneType === "unknown" ? "" : ` (${phoneType})`

    $scope.showAll = {
      moreDetailsViewedBy: false,
      previewedBy: false,
      broadcastedTo: false
    };

    $scope.search = {};

    function filterCaregiver(caregiver) {
      if (!caregiver) {
        return false;
      }

      const { name, zip } = $scope.search;

      if (name || zip) {
        const found = (field, value) => {
          const fieldValue = `${caregiver[field] || ""}`.toLowerCase();
          return value && fieldValue.indexOf(value.toLowerCase()) >= 0;
        };

        if (found("name", name)) {
          return true;
        }

        if (found("zip", zip)) {
          return true;
        }

        return false;
      }
      return true;
    }

    function filterCaregiverInContainer({ caregiver }) {
      return filterCaregiver(caregiver);
    }
    
    let transformedCaregivers = {};
    function resetTransformedCaregivers() {
      const caregivers = DatabaseApi.caregivers() || {};
      transformedCaregivers = {};
      for (const caregiver of Object.values(caregivers)) {
        transformedCaregivers[caregiver.id] = transformCaregiver(caregiver);
      }
    }
  
    function getTransformedCaregiver(caregiver) {
      if (!caregiver) {
        return caregiver;
      }

      return transformedCaregivers[caregiver.id];
    }

    function transformCaregiver(caregiver) {
      const visit = $scope.visit;
      const name = caregiver.firstName + " " + caregiver.lastName;
  
      const distanceInMiles = generalUtils.distanceInMiles(
        visit.patientAddressLoc.lat, visit.patientAddressLoc.lng,
        caregiver.addressGeoLocation.lat, caregiver.addressGeoLocation.lng
      );
  
      const americanZipRegex = /(\d{5})|(\d{5}-\d{4})/g;
      const extractAmericanZipFromAddress = (address) => {
        const foundZipLikeStrings = address.match(americanZipRegex);
        return foundZipLikeStrings ? foundZipLikeStrings.pop() : "";
      };
  
      const zip = extractAmericanZipFromAddress(caregiver.address);
  
      return {
        ...caregiver,
        name: name,
        distanceInMiles: distanceInMiles,
        zip: zip
      };
    }

    function transformCaregiverInContainer(container) {
      return container && container.caregiver
        ? { ...container, caregiver: getTransformedCaregiver(container.caregiver) }
        : container;
    }

    $scope.scrollToTableId = (id) => {
      generalUtils.scrollToElement(id);
    }

    $scope.activeScrolledId = null;
    $scope.onScrolled = (activeChild) => {
      $scope.activeScrolledId = activeChild.id;
    }

    initialize();
  }
