import angular from "angular";
import { caregiverVisitRequestsTable } from "./components/caregivers-visits-requests-table/caregivers-visits-requests-table.component";
import { VisitBroadcastService } from "./visitBroadcast.service";

export function registerVisitBroadcastModule() {
    return (
      angular
        .module("dashboard")
  
        //  Services
        .service("visitBroadcastService", VisitBroadcastService)
  
        // Components
        .component("caregiversVisitsRequestsTable", caregiverVisitRequestsTable)
    );
  }
  