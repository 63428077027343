import { DayOfWeek, LocalTime } from "js-joda";
import { PatternShiftTimes, Shift, ShiftId } from "./caregivers-visits-requests-table.types";

export function generatePatternShiftId(shiftDetails: PatternShiftTimes){
    return `${shiftDetails.dayOfWeek.ordinal()}&&${shiftDetails.startTime.toString()}&&${shiftDetails.endTime.toString()}`
}
  
export function decouplePatternShiftIdToShiftDetails(shiftId: string): PatternShiftTimes{
    const splittedKey = shiftId.split("&&");
    return {
        dayOfWeek: DayOfWeek.of(parseInt(splittedKey[0], 10) + 1),
        startTime: LocalTime.parse(splittedKey[1]),
        endTime: LocalTime.parse(splittedKey[2])
    }
}
  
export function getShiftId(shift: Shift): ShiftId {
    if(shift.type === "PATTERN"){
        return generatePatternShiftId({...shift});
    }

    return shift.visitInstanceId;
}

export function arePatternShiftsEqual(firstShiftTimes: PatternShiftTimes, secondShiftTimes: PatternShiftTimes){
    return firstShiftTimes.dayOfWeek.equals(secondShiftTimes.dayOfWeek) &&
        firstShiftTimes.endTime.equals(secondShiftTimes.endTime) &&
        firstShiftTimes.startTime.equals(secondShiftTimes.startTime);
}