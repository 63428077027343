import { LocalDate } from "js-joda";
import { match } from "ts-pattern";
import { apiErrors } from "../../../../scripts/consts/apiErrors.const";
import { dateUtils } from "../../../../scripts/consts/dateUtils";
import { loadable, Loadable } from "../../../../scripts/consts/loadable.const";
import {
  CompliancePrioritizedCaregiverByPendingDocuments,
  CompliancePriorityPendingDocument,
  ComplianceV2Field,
  ComplianceV2FieldInstance,
  ComplianceV2FieldInstanceWithValue,
} from "../../../../scripts/messages/compliance";
import {
  CaregiverDocumentTypeId,
  CaregiverDocumentUploadId,
  ComplianceV2ItemFieldPossibleValueId,
} from "../../../../scripts/messages/ids";
import { MfModalFactory } from "../../../../scripts/services/mfModalFactory";
import { fmap } from "../../../../scripts/utils/generalUtils";
import { CompService } from "../../comp.service";
import "./prioritized-caregivers.component.scss";

interface ActiveDocumentState {
  isCompliant: boolean;
  effectiveDate: Date;
  expiryDate: Date | null;
  fields: WithNgModel<ComplianceV2FieldInstance>[];
  expires: boolean;
  requireReVerification: boolean;
  files: null;
  expiryDateButtons: {
    title: string;
    active: boolean;
    date: Date;
  }[];
  ignoreFiles: boolean;
  copyFromCaregiverDocumentUploadId?: CaregiverDocumentUploadId;
}

interface ComponentOptions extends angular.IComponentOptions {
  $name: string;
}

type WithNgModel<$Field extends ComplianceV2Field> = $Field extends ComplianceV2Field.Date
  ? $Field & { model: { value: Date | null } }
  : $Field & { model: string | null };

//! @ngInject
class Controller implements ng.IComponentController {
  static readonly $name = "PrioritizedCaregiversScreen";

  row: Loadable<CompliancePrioritizedCaregiverByPendingDocuments> = loadable.loading();
  activeDocument: Loadable<CompliancePriorityPendingDocument> = loadable.loading();
  documentsState: Map<CaregiverDocumentTypeId, ActiveDocumentState> = new Map();

  get canGoToPreviousDocument(): boolean {
    return this.getPreviousDocumentOrNull() !== null;
  }

  get canGoToNextDocument(): boolean {
    return this.getNextDocumentOrNull() !== null;
  }

  constructor(private mfModal: MfModalFactory, private compService: CompService) {}

  $onInit(): void {
    this.fetchData();
  }

  goToPreviousDocument() {
    const previousDocument = this.getPreviousDocumentOrNull();

    if (previousDocument !== null) {
      this.activeDocument = loadable.resolve(previousDocument);
    }
  }

  goToNextDocument() {
    const nextDocument = this.getNextDocumentOrNull();

    if (nextDocument !== null) {
      this.activeDocument = loadable.resolve(nextDocument);
    }
  }

  setActiveDocument(document: CompliancePriorityPendingDocument) {
    this.activeDocument = loadable.resolve(document);
  }

  getPreviousDocumentOrNull(): CompliancePriorityPendingDocument | null {
    return match({ row: this.row, activeDocument: this.activeDocument })
      .with({ row: { type: "Resolved" }, activeDocument: { type: "Resolved" } }, (x) => {
        const index = x.row.value.documents.indexOf(x.activeDocument.value);
        return x.row.value.documents[index - 1] ?? null;
      })
      .otherwise(() => null);
  }

  getNextDocumentOrNull(): CompliancePriorityPendingDocument | null {
    return match({ row: this.row, activeDocument: this.activeDocument })
      .with({ row: { type: "Resolved" }, activeDocument: { type: "Resolved" } }, (x) => {
        return (
          x.row.value.documents[x.row.value.documents.indexOf(x.activeDocument.value) + 1] ?? null
        );
      })
      .otherwise(() => null);
  }

  promptSkip() {
    const modal = this.mfModal.create({
      variant: "warning",
      subject: "Skip caregiver",
      message:
        "Are you sure you want to skip this caregiver? You won't be able to see this caregiver again in the next 30 minutes.",
      onConfirm: async () => {
        if (!loadable.isResolved(this.row)) {
          console.error("row is not resolved");
          return;
        }

        modal.setLoading(true);

        this.compService
          .skipPendingCaregiver({ caregiverId: this.row.value.caregiver.id })
          .then(() => this.fetchData())
          .then(() => modal.close())
          .catch((error) => {
            modal.close();
            this.promptErrorModal(error);
          });
      },
    });
  }

  promptReject() {
    const modal = this.mfModal.create({
      variant: "warning",
      subject: "Reject document",
      showInput: true,
      inputIsRequired: false,
      inputType: "text",
      inputPlaceholder: "Enter a reason for rejecting this document",
      message:
        "Are you sure you want to reject this document? The caregiver will be notified and will be able to upload a new document.",
      onConfirm: async ({ inputModel }) => {
        if (!loadable.isResolved(this.row) || !loadable.isResolved(this.activeDocument)) {
          console.error("row or activeDocument is not resolved");
          return;
        }

        modal.setLoading(true);

        this.compService
          .rejectDocument({
            caregiverId: this.row.value.caregiver.id,
            uploadedDocumentId: this.activeDocument.value.caregiverDocumentUploadId,
            reason: inputModel ?? null,
          })
          .then(() => this.fetchData())
          .catch((error) => this.promptErrorModal(error))
          .finally(() => modal.close());
      },
    });
  }

  promptApprove() {
    const modal = this.mfModal.create({
      subject: "Approve document",
      message: "Are you sure you want to approve this document?",
      onConfirm: async () => {
        if (!loadable.isResolved(this.row) || !loadable.isResolved(this.activeDocument)) {
          console.error("row is not resolved");
          return;
        }

        const activeDocumentState = this.documentsState.get(
          this.activeDocument.value.caregiverDocumentTypeId
        );

        if (activeDocumentState === undefined) {
          console.error("activeDocumentState is undefined");
          return;
        }

        modal.setLoading(true);

        this.compService
          .createComplianceInstance({
            caregiverId: this.row.value.caregiver.id,
            data: {
              caregiverDocumentTypeId: this.activeDocument.value.caregiverDocumentTypeId,
              effectiveDate: dateUtils.dateToLocalDate(activeDocumentState.effectiveDate),
              isCompliant: activeDocumentState.isCompliant,
              caregiverDocumentUploadId: this.activeDocument.value.caregiverDocumentUploadId,
              shouldDuplicateUploadForFollowup: true,
              expiryDate:
                fmap(activeDocumentState.expiryDate, dateUtils.dateToLocalDate) ?? undefined,
              duplicateDocumentUpload: activeDocumentState.copyFromCaregiverDocumentUploadId,
              fields: activeDocumentState.fields.map((field) =>
                match(field)
                  .with(
                    { type: "Text" },
                    (field): ComplianceV2FieldInstanceWithValue => ({
                      id: field.id,
                      type: field.type,
                      value: field.model,
                      isMandatory: field.isMandatory,
                      name: field.name,
                    })
                  )
                  .with(
                    { type: "Dropdown" },
                    (field): ComplianceV2FieldInstanceWithValue => ({
                      id: field.id,
                      type: field.type,
                      value: fmap(
                        field.model,
                        (idAsStr) =>
                          field.possibleValues.find(({ id }) => {
                            return (
                              id === ComplianceV2ItemFieldPossibleValueId.wrap(parseInt(idAsStr))
                            );
                          }) ?? null
                      ),
                      isMandatory: field.isMandatory,
                      name: field.name,
                      possibleValues: field.possibleValues,
                    })
                  )
                  .with(
                    { type: "Date" },
                    (field): ComplianceV2FieldInstanceWithValue => ({
                      id: field.id,
                      type: field.type,
                      value: fmap(field.model.value, dateUtils.dateToLocalDate),
                      isMandatory: field.isMandatory,
                      name: field.name,
                    })
                  )
                  .exhaustive()
              ),
            },
          })
          .then(() => this.fetchData())
          .then(() => modal.close())
          .catch((error) => {
            modal.close();
            this.promptErrorModal(error);
          });
      },
    });

    // POST /agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/compliance_items
  }

  promptErrorModal(error: Error) {
    const modal = this.mfModal.create({
      variant: "danger",
      subject: "Error",
      message: apiErrors.format(error, "Something went wrong"),
      confirmLabel: "Close",
      onConfirm: () => modal.close(),
      hideCancelButton: true,
    });
  }

  private async fetchData() {
    return this.compService
      .nextPendingCaregiver()
      .then((data) => {
        if (data === null) {
          this.row = loadable.reject("No more pending caregivers");
          return;
        }

        this.row = loadable.resolve(data);
        this.activeDocument = loadable.resolve(data.documents[0]);
        data.documents.forEach((document) => {
          this.documentsState.set(document.caregiverDocumentTypeId, {
            isCompliant: false,
            effectiveDate: new Date(),
            expiryDate: null,
            fields: document.fields.map((field) => {
              return match(field)
                .with({ type: "Text" }, (field) => ({ ...field, model: null }))
                .with({ type: "Dropdown" }, (field) => ({ ...field, model: null }))
                .with({ type: "Date" }, (field) => ({ ...field, model: { value: null } }))
                .exhaustive();
            }),
            expires: document.expires,
            requireReVerification: document.requireReVerification,
            files: null,
            ignoreFiles: true,
            expiryDateButtons: [
              {
                title: "1 week",
                active: false,
                date: dateUtils.localDateToDate(LocalDate.now().plusWeeks(1)),
              },
              {
                title: "1 month",
                active: false,
                date: dateUtils.localDateToDate(LocalDate.now().plusMonths(1)),
              },
              {
                title: "1 year",
                active: false,
                date: dateUtils.localDateToDate(LocalDate.now().plusYears(1)),
              },
              {
                title: "Custom date",
                active: false,
                date: new Date(),
              },
            ],
          });
        });
      })
      .catch((error) => {
        this.promptErrorModal(error);
        this.row = apiErrors.format(error, "Something went wrong");
      });
  }
}

export const PrioritizedCaregiversScreenComponent: ComponentOptions = {
  $name: "prioritizedCaregiversScreen",
  templateUrl:
    "admin/modules/compliance/components/prioritized-caregivers/prioritized-caregivers.component.html",
  controller: Controller,
  controllerAs: "ctrl",
};
