export class AgencyMemberId {
  "AgencyMemberId": AgencyMemberId;
}
export class UserId {
  "UserId": UserId;
}

export class AgencyTeamId {
  "AgencyTeamId": AgencyTeamId;
}

export class AgencyApiUserId {
  "AgencyApiUserId": AgencyApiUserId;
}

export class AgencyId {
  "AgencyId": AgencyId;
  static wrap = (id: number): AgencyId => id as unknown as AgencyId;
  static unwrap = (id: AgencyId): number => id as unknown as number;
}

export class OfficeId {
  "OfficeId": OfficeId;
}

export class CaregiverId {
  "CaregiverId": CaregiverId;
  static wrap = (id: number): CaregiverId => id as unknown as CaregiverId;
  static unwrap = (id: CaregiverId): number => id as unknown as number;
}

export class PatientId {
  "PatientId": PatientId;
}

export class ContractTypeId {
  "ContractTypeId": ContractTypeId;
}
export class PayerId {
  "PayerId": PayerId;
}
export class PatientDocumentId {
  "PatientDocumentId": PatientDocumentId;
}

export class PatientTaskInstanceId {
  "PatientTaskInstanceId": PatientTaskInstanceId;
}

export class VisitInstanceId {
  "VisitInstanceId": VisitInstanceId;
}

export class PatientDocumentTypeId {
  "PatientDocumentTypeId": PatientDocumentTypeId;
}

export class PatientDocumentVersionId {
  "PatientDocumentVersionId": PatientDocumentVersionId;
}

export class PatientTaskId {
  "PatientTaskId": PatientTaskId;
}

export class PatientContractDiagnosisCodeId {
  "PatientContractDiagnosisCodeId": PatientContractDiagnosisCodeId;
}

export class ICD10CodeId {
  "ICD10CodeId": ICD10CodeId;
}

export class PatientContractId {
  "PatientContractId": PatientContractId;
}

export class ServiceCodeId {
  "ServiceCodeId": ServiceCodeId;
}

export class NoteId {
  "NoteId": NoteId;
}
export class MasterWeekId {
  "MasterWeekId": MasterWeekId;
}
export class VisitBatchId {
  "VisitBatchId": VisitBatchId;
}

export class VisitBroadcastId {
  "VisitBroadcastId": VisitBroadcastId;
}
export class NoteSubjectId {
  "NoteSubjectId": NoteSubjectId;
}

export class NotePredefinedAnswerId {
  "NotePredefinedAnswerId": NotePredefinedAnswerId;
}
export class PayrollCodeId {
  "PayrollCodeId": PayrollCodeId;
}

export class PatientTaskTemplateId {
  "PatientTaskTemplateId": PatientTaskTemplateId;
}

export class PlanOfCareTypeId {
  "PlanOfCareTypeId": PlanOfCareTypeId;
}

export class PlanOfCareId {
  "PlanOfCareId": PlanOfCareId;
}

export class PlanOfCareItemId {
  "PlanOfCareItemId": PlanOfCareItemId;
}

export class DutySheetId {
  "DutySheetId": DutySheetId;
}

export class PatientDocumentScheduleId {
  "PatientDocumentScheduleId": PatientDocumentScheduleId;
}

export class PlanOfCareDutyId {
  "PlanOfCareDutyId": PlanOfCareDutyId;
}

export class TimesheetId {
  "TimesheetId": TimesheetId;
}

export class CaregiverDocumentTypeId {
  "CaregiverDocumentTypeId": CaregiverDocumentTypeId;
  static wrap = (id: number): CaregiverDocumentTypeId => id as unknown as CaregiverDocumentTypeId;
  static unwrap = (id: CaregiverDocumentTypeId): number => id as unknown as number;
}

export class CaregiverComplianceInstanceId {
  "CaregiverComplianceInstanceId": CaregiverComplianceInstanceId;
}

export class ComplianceV2ItemFieldId {
  "ComplianceV2ItemFieldId": ComplianceV2ItemFieldId;
  static wrap = (id: number): ComplianceV2ItemFieldId => id as unknown as ComplianceV2ItemFieldId;
  static unwrap = (id: ComplianceV2ItemFieldId): number => id as unknown as number;
}

export class ComplianceV2ItemFieldPossibleValueId {
  "ComplianceV2ItemFieldPossibleValueId": ComplianceV2ItemFieldPossibleValueId;
  static wrap = (id: number): ComplianceV2ItemFieldPossibleValueId => id as unknown as ComplianceV2ItemFieldPossibleValueId;
  static unwrap = (id: ComplianceV2ItemFieldPossibleValueId): number => id as unknown as number;
}

export class CaregiverDocumentUploadId {
  "CaregiverDocumentUploadId": CaregiverDocumentUploadId;
  static wrap = (id: number): CaregiverDocumentUploadId => id as unknown as CaregiverDocumentUploadId;
  static unwrap = (id: CaregiverDocumentUploadId): number => id as unknown as number;
}

export class CertificationPeriodId {
  "CertificationPeriodId": CertificationPeriodId;
}

export type CaregiverCertification =
  | "CBSA"
  | "CDPAP"
  | "CH"
  | "CMT"
  | "CNA"
  | "COMP"
  | "DCW"
  | "ESC"
  | "FS"
  | "GNA"
  | "HCSS"
  | "HHA"
  | "HM"
  | "HMK"
  | "HSK"
  | "ILST"
  | "LPN"
  | "MSW"
  | "NT"
  | "OT"
  | "PA"
  | "PBIS"
  | "PC"
  | "PCA"
  | "PT"
  | "PTA"
  | "RD"
  | "RESP"
  | "RN"
  | "RT"
  | "SCI"
  | "SCM"
  | "SDP"
  | "SHC"
  | "SHHA"
  | "SPC"
  | "ST";

export class HRSectionStageId {
  "HRSectionStageId": HRSectionStageId;
}

export type Id =
  | AgencyMemberId
  | UserId
  | AgencyId
  | OfficeId
  | CaregiverId
  | PatientId
  | ContractTypeId
  | PatientDocumentId
  | PatientTaskInstanceId
  | VisitInstanceId
  | PatientDocumentTypeId
  | PatientDocumentVersionId
  | PatientTaskId
  | PatientContractDiagnosisCodeId
  | ICD10CodeId
  | ServiceCodeId
  | MasterWeekId
  | NoteId
  | NoteSubjectId
  | NotePredefinedAnswerId
  | PayrollCodeId
  | VisitBatchId
  | VisitBroadcastId
  | PatientContractId
  | ServiceCodeId
  | PayrollCodeId
  | PatientTaskTemplateId
  | PlanOfCareTypeId
  | PlanOfCareId
  | PlanOfCareItemId
  | DutySheetId
  | PlanOfCareDutyId
  | PatientDocumentScheduleId
  | AgencyTeamId
  | PayerId
  | CaregiverDocumentUploadId
  | HRSectionStageId;
