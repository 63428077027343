import { CaregiverId, VisitBroadcastId } from "../../scripts/messages/ids";
import { Api } from "../../scripts/services/Api";
import { Endpoint } from "../../scripts/services/endpoint.service";
import { PartialAssignResponse, RejectCaregiverVisitRequestsBody, VisitBroadcastPartialAssingParams } from "./visitBroadcast.types";

//! @ngInject
export class VisitBroadcastService {
    constructor(
      private api: Api,
      private endpoint: Endpoint,
      private $rootScope: ng.IRootScopeService
    ) {}

    rejectCaregiverVisitRequest = (caregiverId: CaregiverId, visitBroadcastId: VisitBroadcastId, requestBody: RejectCaregiverVisitRequestsBody) => {
        const url = this.endpoint({
            path: "agency/:agencyId/agency_members/:agencyMemberId/visits/:visitBroadcastId/caregivers/:caregiverId/ignore_request2",
            params: {
              agencyId: this.$rootScope.agencyId,
              agencyMemberId: this.$rootScope.agencyMemberId,
              visitBroadcastId,
              caregiverId
            },
          });

        return this.api.put(url, requestBody);
    }

    assignCaregiversToVisit = (visitBroadcastId: VisitBroadcastId, requestBody: VisitBroadcastPartialAssingParams) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/broadcast_visits/:visitBroadcastId/assign_caregivers_partially",
            params: {
              agencyId: this.$rootScope.agencyId,
              agencyMemberId: this.$rootScope.agencyMemberId,
              visitBroadcastId
            },
          });

        return this.api.post<PartialAssignResponse>(url, requestBody);
    }
}