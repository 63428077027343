import angular from "angular";
import { multiselectComponent } from "./components/multiselect/multiselect.component";
import { pieChartComponent } from "./components/pie-chart/pie-chart.component";
import { progressBarComponent } from "./components/progress-bar/progress-bar.component";
import { selectAllCheckboxComponent } from "./components/select-all-checkbox/select-all-checkbox.component";

export function registerSharedModule() {
  return (
    angular
      .module("dashboard")
      // Components
      .component(pieChartComponent.$name, pieChartComponent)
      .component(multiselectComponent.$name, multiselectComponent)
      .component(selectAllCheckboxComponent.$name, selectAllCheckboxComponent)
      .component(progressBarComponent.$name, progressBarComponent)
  );
}
